import React from "react";
import Layout from "../components/layout/layout";
import SEOComponent from "../components/seo";
import GenerateDemoChat from "../components/generatedemochat/GenerateDemoChat";

const DemoChat = () => (
    <Layout mode={true} sticky_footer={false}>
        <SEOComponent title="Amber by inFeedo | Chat Generator" />
        <GenerateDemoChat />
    </Layout>
)

export default DemoChat