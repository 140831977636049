import React, { Component } from 'react';
import { navigate } from 'gatsby'
import infeedo_logo from '../../../static/images/infeedo.svg';
import axios from '../../helper/Axios';
import { NotificationManager } from 'react-notifications';
import classnames from 'classnames';
import { looseFocus } from '../../helper/looseFocus';
import GeneratedemoLinkModal from './GenerateDemoLinkModal';
import { getBaseURL } from '../../helper/getApiBase';
import ReactTooltip from 'react-tooltip';
import handleException from '../../helper/errorHandling';

export default class componentName extends Component {

    state = {
        name: '',
        company: '',
        ceo: '',
        selected_stage: 'Select Stage',
        selected_language: 'Select Language',
        loading: false,
        stages: [],
        languages: {},
        stage_map: {},
        chat_email: '',
        chat_token: '',
        is_active: false,
        regions: {
            'us-east-1': 'Rest of the World',
            // 'eu-west-1': 'Europe',
            // 'ap-south-1': "India",
        },
        regions_reverse: {
            'Rest of the World': 'us-east-1',
            // 'Europe': 'eu-west-1',
            // 'India': 'ap-south-1'
        },
        selected_region: ''
    }

    // component mounts
    // ----------------
    componentDidMount() {
        this.getToken();
    }

    componentDidUpdate(prevProps, prevState) {
        const { selected_stage } = this.state;
        if (prevState.selected_stage !== selected_stage) {
            this.fetchLanguages();
        }
    }

    componentWillUnmount() {
        sessionStorage.clear();
    }

    getToken = () => {
        const token = this.getParameterByName('token');
        if (!token && !sessionStorage.token) {
            NotificationManager.error('Unauthorized!');
            this.signOut();
        }
        if (!sessionStorage.token && token) {
            sessionStorage.setItem('token', token);
        }
        this.fetchStages();
    }

    signOut = () => {
        sessionStorage.clear();
        navigate('/generate-demo-chat');
    }

    getParameterByName = (name, url = window.location.href) => {
        name = name.replace(/[[\]]/g, '\\$&');
        var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }

    fetchLanguages = async () => {
        const { stage_map, selected_stage } = this.state;
        if (stage_map[selected_stage]) {
            try {
                const response = await axios.get(`/v1/exampleStages/${stage_map[selected_stage]}/languages`);
                let language_map = {}
                response.data.forEach((data, index) => {
                    language_map = { ...language_map, ...data };
                });
                this.setState({ languages: language_map });
            } catch (error) {
                handleException(error);
            }
        }
    }

    // Fetch stages
    // --------------------
    fetchStages = async () => {
        try {
            const response = await axios.get('/v1/exampleStages');
            this.setState({ stages: response.data }, () => this.createStageIdMapping());
        } catch (error) {
            handleException(error);
        }
    }

    // XOR helper method
    // -----------------
    keyCharAt(key, i) {
        return key.charCodeAt(Math.floor(i % key.length));
    }

    onChangeOfRegion = async (e) => {
        this.setState({ [e.target.name]: e.target.value });
        let { regions_reverse } = this.state;
        let region = regions_reverse[e.target.value];
        sessionStorage.removeItem("current_region_url");
        await getBaseURL(region, "dashboard");
        this.fetchStages();
    }

    // create chat
    // -----------
    createChat = async (e) => {
        e.preventDefault();
        looseFocus();
        const { name, company, ceo, stage_map, selected_stage, selected_region, languages, selected_language } = this.state;
        this.setState({ loading: true });
        if (selected_stage === 'Select Stage') {
            NotificationManager.info('Select a Stage');
            this.setState({ loading: false });
            return;
        }
        if (selected_language === 'Select Language' || languages[selected_language] === undefined) {
            NotificationManager.info('Select a Language');
            this.setState({ loading: false });
            return;
        }
        if (selected_region === 'Select Region' || !selected_region.length) {
            NotificationManager.error('Region is required');
            this.setState({ loading: false });
            return;
        }
        try {
            const response = await axios.post(`/v1/generateDemo/${stage_map[selected_stage]}/survey_languages/${languages[selected_language]}`, { name, ceo, company });
            if (response.status === 200) {
                this.setState({ chat_email: response.data.email, chat_token: response.data.token }, () => this.openChatLinkModal());
            }
        } catch (error) {
            handleException(error);
        }
        this.setState({ loading: false });
    }

    // create stage id mapping
    // -----------------------
    createStageIdMapping = () => {
        const { stages } = this.state;
        const stage_map = {};
        stages.forEach(stage => {
            stage_map[`${stage.duration}-${stage.unit}`] = stage.id;
        });
        this.setState({ stage_map });
    }

    // On change input values map to state
    // -----------------------------------
    onChange = e => {
        this.setState({ [e.target.name]: e.target.value });
    }

    // open chat link modal
    // --------------------
    openChatLinkModal = () => {
        this.setState({ is_active: true });
    }

    // close chat link modal
    // --------------------
    closeChatLinkModal = () => {
        this.setState({ is_active: false, chat_email: '', chat_token: '' });
    }

    render() {
        const { loading, name, company, ceo, stages, selected_stage, chat_email, chat_token, is_active, regions, selected_region, languages, selected_language } = this.state;
        return (
            <div className="columns pd-25" id="demo-chat">
                <form className="column is-4 mar-auto" onSubmit={this.createChat}>
                    <figure className="app-logo">
                        <img src={infeedo_logo} width="130px" alt="amber logo" />
                    </figure>
                    <div className="signout-button-demo">
                        <button onClick={this.signOut} className="button is-link is-outlined">Sign Out</button>
                    </div>
                    <h1 className="login-heading">Amber Chat Configuration</h1>
                    <div className="field">
                        <div className="select">
                            <select name="selected_region" value={selected_region} onChange={this.onChangeOfRegion}>
                                <option key="select">Select Region</option>
                                {
                                    Object.keys(regions).map((region, index) => (
                                        <option key={index} id={region}>{regions[region]}</option>
                                    ))
                                }
                            </select>
                        </div>
                    </div>
                    <div className="field">
                        <p className="control">
                            <input onChange={this.onChange} value={name} name="name" className="input" type="text" placeholder="Employee Name" id="name" required />
                        </p>
                    </div>
                    <div className="field">
                        <p className="control">
                            <input onChange={this.onChange} value={company} name="company" className="input" type="text" placeholder="Company" id="company" required />
                        </p>
                    </div>
                    <div className="field">
                        <p className="control">
                            <input onChange={this.onChange} value={ceo} name="ceo" className="input" type="text" placeholder="CEO" id="ceo" required />
                        </p>
                    </div>
                    <div className="field">
                        <div className="select">
                            <select name="selected_stage" value={selected_stage} onChange={this.onChange}>
                                <option key="select">Select Stage</option>
                                {stages.length ? stages.sort((a, b) => a.unit.localeCompare(b.unit)).map((stage, index) => (
                                    <option key={index} id={stage.id}>{stage.duration}-{stage.unit}</option>
                                ))
                                    : <option key="loading">loading...</option>}
                            </select>
                        </div>
                    </div>
                    <ReactTooltip id="stage-select-tooltip" className="previous-tooltip" multiline={true} effect="solid" place="right" html={true} disable={Object.entries(languages).length && selected_stage !== "Select Stage" ? true : false} />
                    <div className="field">
                        <div className={classnames("select ", { "is-disabled": (!Object.entries(languages).length || selected_stage === "Select Stage") })} data-for="stage-select-tooltip" data-tip={!Object.entries(languages).length || selected_stage === "Select Stage" ? "Please select a stage" : ''}>
                            <select name="selected_language" value={selected_language} onChange={this.onChange}>
                                <option key="select">Select Language</option>
                                {Object.entries(languages).length ? Object.entries(languages).map(([language, id], index) => (
                                    <option key={index} id={id}>{language}</option>
                                ))
                                    : <option key="loading">loading...</option>}
                            </select>
                        </div>
                    </div>
                    <div className="field pd-top-12">
                        <p className="control has-text-right">
                            <button type="submit" className={classnames('button is-link', { 'is-loading': loading })} disabled={!(name.length && company.length && ceo.length && selected_stage !== 'Select Stage' && selected_language !== 'Select Language')}>
                                &nbsp;&nbsp;Generate&nbsp;&nbsp;
                            </button>
                        </p>
                    </div>
                    <br />
                    <br />
                </form>
                <GeneratedemoLinkModal is_active={is_active} onClose={this.closeChatLinkModal} chat_token={chat_token} chat_email={chat_email} />
            </div>
        )
    }
}
